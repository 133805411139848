<template>
  <div class="width-100 height-100 max_box">
    <!-- <div class="audio_box none">
      <audio src="audio/zhongxin2/Loveless.mp3" ref="bgAudio"></audio>
      <audio
        ref="audio1"
        src="https://res.idtcdn.com/Cube/video-config/con-929fd015.mp3"
      ></audio>
      <audio
        ref="audio2"
        src="https://res.idtcdn.com/Cube/video-config/con-174857b4.mp3"
      ></audio>
      <audio
        ref="audio3"
        src="https://res.idtcdn.com/Cube/video-config/con-efaaafd6.mp3"
      ></audio>
      <audio
        ref="audio4"
        src="https://res.idtcdn.com/Cube/video-config/con-595018e2.mp3"
      ></audio>
    </div> -->
    <div class="card1 card" v-show="currentIndex == 1">
      <div class="card1_box">
        <div class="img1_box none">
          <img src="../../assets/img/guangfa/img1.png" alt="" />
        </div>
        <div class="img2_box none">
          <img src="../../assets/img/guangfa/img2.png" alt="" />
        </div>
        <div class="date none" id="date">东莞虎门营业部</div>
        <div class="time_box none">
          <!-- <div class="time_img">
            <img src="../../assets/img/guangfa/time.png" alt="" />
          </div> -->
          <div class="time none" id="time">2021年6月</div>
        </div>
        <div class="text1 text bold none" id="text1">证券金融业务经营</div>
        <div class="text2 text bold none" id="text2">情况汇报</div>
        <div class="bottom none">
          <div>更新时间</div>
          <div>2022年3月17日</div>
        </div>
      </div>
      <div class="logo_box">
        <img src="../../assets/img/guangfa/logo.png" alt="" />
      </div>
    </div>
    <div class="card2 card" v-show="currentIndex == 2">
      <div class="logo_box">
        <img src="../../assets/img/guangfa/logo.png" alt="" />
      </div>
      <h2 class="title none" id="title1">融资融券利息收入及完成率情况</h2>
      <div class="sub_title none">2021年1-6月(万元)</div>
      <div class="content">
        <div id="chart1" style="width: 100%; height: 800px"></div>
        <div class="box1 none">
          <div class="Title">总收入</div>
          <div class="sum bold none">
            <span id="number1">230660.45</span><span>万元</span>
          </div>
        </div>
        <div class="box2 none">
          <div class="Title">完成率情况</div>
          <div class="num bold none">
            <span id="number2">74.55</span><span>%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card3 card" v-show="currentIndex == 3">
      <div class="logo_box">
        <img src="../../assets/img/guangfa/logo.png" alt="" />
      </div>
      <div class="company_name">东莞虎门营业部</div>
      <div class="content">
        <h2 class="title" id="title2">融资融券利息收入及完成率情况</h2>
        <div class="sub_title">2021年1-6月(万元)</div>
        <ul v-if="!isCollapsed" class="ul ul1" id="ul1">
          <li v-for="(item, i) in dataArr.slice(0, 3)" :key="i" class="none">
            <div class="head">
              <span class="left">{{ item.name }}</span>
              <span class="right" v-if="i < 3"
                >排行第{{ ["一", "二", "三"][i] }}</span
              >
            </div>
            <div class="center">
              <span>{{ item.val1 }}</span>
              <span>{{ item.val2 }}</span>
              <span>{{ item.val3 }}</span>
              <span>{{ item.val4 }}</span>
            </div>
            <div class="bottom">
              <span>合计</span>
              <span>合计市占率</span>
              <span>市占率目标值</span>
              <span>完成率</span>
            </div>
            <div class="img_box" v-if="i < 3">
              <img :src="srcList[i]" alt="" />
            </div>
          </li>
        </ul>
        <div class="hand_box none">
          <img src="../../assets/img/guangfa/hand.png" alt="" />
        </div>
        <ul class="ul ul2" id="ul2">
          <li
            :style="`background:url(${srcList2[i]}) center`"
            v-for="(item, i) in dataArr.slice(0, 3)"
            :key="i"
            class="none"
          >
            <div class="top">{{ item.name }}</div>
            <div class="center" :id="`${'Number' + i}`">
              {{ item.val1 }}
            </div>
            <div class="bottom">合计</div>
          </li>
        </ul>
        <div class="ul3_box" id="ul3_box">
          <ul class="ul ul3" id="ul3">
            <li v-for="(item, i) in dataArr.slice(3)" :key="i" class="none">
              <div class="head">
                <span class="left">{{ item.name }}</span>
              </div>
              <div class="center">
                <span>{{ item.val1 }}</span>
                <span>{{ item.val2 }}</span>
                <span>{{ item.val3 }}</span>
                <span>{{ item.val4 }}</span>
              </div>
              <div class="bottom">
                <span>合计</span>
                <span>合计市占率</span>
                <span>市占率目标值</span>
                <span>完成率</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card4 card" v-show="currentIndex == 4">
      <div class="logo_box">
        <img src="../../assets/img/guangfa/logo.png" alt="" />
      </div>
      <div class="company_name">东莞虎门营业部</div>
      <h2 class="title none" id="title3">融资融券余额及变动情况</h2>
      <div class="sub_title none">2021年1-6月(亿元)</div>
      <bar-history-chart
        ref="refBarRaceChart1"
        id="refBarRaceChart1"
        customClass="c_bar1"
      >
      </bar-history-chart>
      <div class="updown_box">
        <div class="upDown none" v-for="(item, index) in upDowns" :key="index">
          <span style="margin-right：5px">
            <img :src="srcList3[0]" alt="" v-if="item > 0" />
            <img :src="srcList3[1]" alt="" v-else />
          </span>
          <span
            :class="item > 0 ? 'color_red' : 'color_green'"
            :id="`${'per' + index}`"
            >{{ item }}</span
          ><span :class="item > 0 ? 'color_red' : 'color_green'">%</span>
        </div>
      </div>
      <div class="rank_box none">
        <div class="rank" v-for="(item, index) in upDowns" :key="index">
          <div>
            <img :src="srcList4[index]" alt="" v-if="index < 3" />
          </div>
          <div class="numbers" v-if="index + 1 > 3">
            <div class="number_box">{{ index + 1 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams2 } from "../../utils/common";
import { sleep, numberRoll } from "@/utils/common";
import ApiServe from "@/api/index";
import { anyElement } from "@/components/anyElement.js";
import BarHistoryChart from "@/components/barHistoryChart/index.vue";
export default {
  name: "GuangFa",
  components: { BarHistoryChart },
  data() {
    return {
      device_id: null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      currentIndex: 1,
      isCollapsed: false,
      dataArr: [
        {
          name: "惠州下埔路",
          val1: "1763.42",
          val2: "0.03%",
          val3: "0.036%",
          val4: "67.41%",
        },
        {
          name: "东莞虎门",
          val1: "1749.15",
          val2: "0.03%",
          val3: "0.037%",
          val4: "64.72%",
        },
        {
          name: "中山小榄",
          val1: "1619.83",
          val2: "0.028%",
          val3: "0.029%",
          val4: "76.08%",
        },
        {
          name: "中山市中山四路",
          val1: "1369.2",
          val2: "0.024%",
          val3: "0.032%",
          val4: "58.27%",
        },
        {
          name: "东莞东城",
          val1: "1205.38",
          val2: "0.021%",
          val3: "0.026%",
          val4: "65%",
        },
        {
          name: "中山兴中道",
          val1: "1121.38",
          val2: "0.019%",
          val3: "0.033%",
          val4: "46.76%",
        },

        {
          name: "东莞长安",
          val1: "999.49",
          val2: "0.017%",
          val3: "0.018%",
          val4: "75.11%",
        },
        {
          name: "惠州博罗",
          val1: "965.94",
          val2: "0.017%",
          val3: "0.017%",
          val4: "78.86%",
        },
        {
          name: "东莞中堂",
          val1: "928.59",
          val2: "0.016%",
          val3: "0.016%",
          val4: "79.60%",
        },
        {
          name: "东莞大朗",
          val1: "468.48",
          val2: "0.008%",
          val3: "0.008%",
          val4: "81.62%",
        },
        {
          name: "惠州文明一路",
          val1: "405.25",
          val2: "0.007%",
          val3: "0.006%",
          val4: "85.93%",
        },
        {
          name: "河源越王大道",
          val1: "270.81",
          val2: "0.005%",
          val3: "0.003%",
          val4: "116%",
        },

        {
          name: "东莞塘厦",
          val1: "268.93",
          val2: "0.005%",
          val3: "0.005%",
          val4: "71.38%",
        },
        {
          name: "东莞厚街",
          val1: "173.46",
          val2: "0.003%",
          val3: "0.005%",
          val4: "50.17%",
        },
        {
          name: "中山三乡",
          val1: "117.36",
          val2: "0.002%",
          val3: "0.002%",
          val4: "62.5%",
        },
        {
          name: "东莞常平",
          val1: "96.53",
          val2: "0.002%",
          val3: "0.001%",
          val4: "112%",
        },
        {
          name: "财富管理中心",
          val1: "69.05",
          val2: "0.001%",
          val3: "0.0001%",
          val4: "600%",
        },
      ],
      upDowns: [
        "7.33",
        "42.75",
        "2.41",
        "0.26",
        "8.64",
        "2.35",
        "36.25",
        "12.65",
        "4.14",
        "-31.12",
        "15.10",
        "10.10",
        "64.79",
        "6.20",
        "-46.93",
        "11.68",
        "18.24",
      ],
      srcList: [
        "https://res.idtcdn.com/Cube/video-config/con-b85c0740.png",
        "https://res.idtcdn.com/Cube/video-config/con-f176b16b.png",
        "https://res.idtcdn.com/Cube/video-config/con-02f30f92.png",
      ],
      srcList2: [
        "https://res.idtcdn.com/Cube/video-config/con-dbe9c4c1.png",
        "https://res.idtcdn.com/Cube/video-config/con-5f3a8321.png",
        "https://res.idtcdn.com/Cube/video-config/con-4f391f29.png",
      ],
      srcList3: [
        "https://res.idtcdn.com/Cube/video-config/con-7cc657a4.png",
        "https://res.idtcdn.com/Cube/video-config/con-53c0992d.png",
      ],
      srcList4: [
        "https://res.idtcdn.com/Cube/video-config/con-ca845858.png",
        "https://res.idtcdn.com/Cube/video-config/con-f83af9eb.png",
        "https://res.idtcdn.com/Cube/video-config/con-be049063.png",
      ],
      raceData: {
        covid19: [
          ["Covid19 OverTime", "2021-01", "2021-06"],
          ["东莞虎门", "2.85", "3.06"],
          ["中山小榄", "2.12", "3.03"],
          ["惠州下埔路", "2.67", "2.73"],
          ["中山市中山四路", "2.32", "2.32"],
          ["东莞东城", "1.87", "2.03"],
          ["中山兴中道", "1.87", "1.91"],
          ["东莞中堂", "1.38", "1.88"],
          ["东莞长安", "1.67", "1.88"],
          ["惠州博罗", "1.69", "1.76"],
          ["东莞大朗", "1.17", "0.80"],
          ["惠州文明一路", "0.66", "0.76"],
          ["东莞塘厦", "0.46", "0.50"],
          ["河源越王大道", "0.29", "0.48"],
          ["东莞厚街", "0.28", "0.29"],
          ["财富管理中心", "0.40", "0.21"],
          ["中山三乡", "0.17", "0.19"],
          ["东莞常平", "0.15", "0.18"],
        ],
      },
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  mounted() {
    if (this.ip && this.port) {
      console.log(666);
      const params = {
        ip: this.ip,
        port: this.port,
        offset_x: this.offset_x,
        offset_y: this.offset_y,
        width: this.width,
        height: this.height,
      };
      ApiServe.recordstartPc(this.newIpPort, params)
        .then(({ code }) => {})
        .catch((err) => {
          console.log(err);
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
      this.load();
    } else {
      // alert("没获取到ip,port");
      this.load();
    }
  },
  methods: {
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 3000,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },

    playOut() {
      this.txt.goOut.restart();
    },
    playBarHistoryIn(refName) {
      this.$refs[refName].BarHistoryChart(this.raceData.covid19, {
        width: 500,
        height: 950,
        margin: { top: 35, left: 220, bottom: 5, right: 75 },
        inDuration: 2000,
        delay: 300,
        playDuration: 2000,
        yPadding: 0.3,
        count: 17,
        ease: "easeBackOut",
      });
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },

    endRecord() {
      this.audioData = [
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-d9d4ce4d.mp3",
          timeIndex: 0,
          audioLen: 3,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-f3e04916.mp3",
          timeIndex: 5,
          audioLen: 11,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-b766d811.mp3",
          timeIndex: 17,
          audioLen: 16,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-83384256.mp3",
          timeIndex: 36,
          audioLen: 3,
        },
      ];
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 49,
        videmBgm: "https://res.idtcdn.com/Cube/video-config/con-ec094cc4.mp3",
        audiosOrderList: this.audioData,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    // 页面card一个接一个播放
    async load() {
      await this.playCard1();
      await sleep(1000);
      await this.playCard2();
      await sleep(1000);
      await this.playCard3();
      await sleep(1000);
      await this.playCard4();
      await sleep(6000);
      if (this.ip) {
        this.endRecord();
      }
    },

    async playCard1() {
      // card1
      this.currentIndex = 1;
      // this.$refs.audio1.play();
      $(".card1 .img2_box").removeClass("none");
      $(".card1 .img2_box").addClass(
        "animate__animated animate__fadeInLeftBig"
      );
      $(".card1 .date").removeClass("none");
      $(".card1 .date").addClass("animate__animated animate__fadeInLeftBig");

      $(".card1 .time_box").removeClass("none");
      $(".card1 .time_box").addClass(
        "animate__animated animate__fadeInLeftBig"
      );
      $(".card1 .time").removeClass("none");
      $(".card1 .time").addClass("animate__animated animate__fadeInLeft");
      await sleep(200);
      $(".card1 .img1_box").removeClass("none");
      $(".card1 .img1_box").addClass(
        "animate__animated animate__fadeInRightBig"
      );
      await sleep(1000);
      $(".card1 .text1").removeClass("none");
      this.playIn("runIn", "typeOut", "#text1");
      await sleep(500);
      $(".card1 .text2").removeClass("none");
      $(".card1 .text2").addClass("animate__animated animate__fadeInRight");
      await sleep(1000);
      $(".card1 .bottom").removeClass("none");
      $(".card1 .bottom").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      //出场
      $(".card1_box").addClass("animate__animated animate__fadeOut");
    },
    async playCard2() {
      //card2
      this.currentIndex = 2;
      // this.$refs.audio2.play();
      // $(".card2 .company_name").removeClass("none");
      // $(".card2 .company_name").addClass("animate__animated animate__fadeIn");

      $(".card2 h2").removeClass("none");
      this.playIn("typeIn", "typeOut", "#title1");
      await sleep(1000);
      $(".card2 .sub_title").removeClass("none");
      $(".card2 .sub_title").addClass("animate__animated animate__slideInUp");
      await sleep(1000);
      this.loadChart1();
      await sleep(1000);
      $(".card2 .box1").removeClass("none");
      $(".card2 .box1").addClass("animate__animated animate__fadeInLeft");
      $(".card2 .box1 .title").removeClass("none");
      $(".card2 .box1 .title").addClass(
        "animate__animated animate__fadeInLeft"
      );
      await sleep(1000);
      $(".card2 .box1 .sum").removeClass("none");
      this.playNumVaryIn("#number1");
      await sleep(1000);
      $(".card2 .box2").removeClass("none");
      $(".card2 .box2").addClass("animate__animated animate__fadeInLeft");
      await sleep(500);
      $(".card2 .box2 .title").removeClass("none");
      $(".card2 .box2 .title").addClass(
        "animate__animated animate__fadeInLeft"
      );
      await sleep(1000);
      $(".card2 .box2 .num").removeClass("none");
      this.playNumVaryIn("#number2");
      await sleep(4500);
      //出场
      $(".card2 .content").addClass("animate__animated animate__fadeOut");
    },
    async playCard3() {
      // card1
      this.currentIndex = 3;
      $(".card2 .company_name").removeClass("none");
      $(".card2 .company_name").addClass("animate__animated animate__fadeIn");
      await sleep(500);
      $(".card3 #ul1 li").eq(0).removeClass("none");
      $(".card3 #ul1 li")
        .eq(0)
        .addClass("animate__animated animate__fadeInLeft");
     
      await sleep(1000);
      $(".card3 #ul1 li").eq(1).removeClass("none");
      $(".card3 #ul1 li")
        .eq(1)
        .addClass("animate__animated animate__fadeInLeft");
         await sleep(500);
      $(".card3 .hand_box").removeClass("none");
      $(".card3 .hand_box").addClass(
        "animate__animated animate__bounceInRight"
      );
      await sleep(1000);
      $(".card3 #ul1 li").eq(2).removeClass("none");
      $(".card3 #ul1 li")
        .eq(2)
        .addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card3 #ul1").addClass("animate__animated animate__fadeOut");
      $(".card3 .hand_box").addClass("none");
      await sleep(500);
      $(".card3 #ul2 li").removeClass("none");
      $(".card3 #ul2 li").addClass("animate__animated animate__fadeIn");
      $(".card3 .hand_box").removeClass("none");
      $(".card3 .hand_box").addClass("position2");
      this.playNumVaryIn("#Number0");
      this.playNumVaryIn("#Number1");
      this.playNumVaryIn("#Number2");
      await sleep(1000);
      $(".card3 #ul3 li").removeClass("none");
      $("#ul3_box").animate(
        {
          scrollTop: $(".ul3_box #ul3").eq(0).height() - $("#ul3_box").height(),
        },
        8000
      );
      await sleep(11000);
      //出场
      $(".card3 .content").addClass("animate__animated animate__fadeOut");
    },
    async playCard4() {
      //card4
      this.currentIndex = 4;
      // this.$refs.audio4.play();
      $(".card4 h2").removeClass("none");
      this.playIn("typeIn", "typeOut", "#title3");
      await sleep(1000);
      $(".card4 .sub_title").removeClass("none");
      $(".card4 .sub_title").addClass("animate__animated animate__slideInUp");
      await sleep(1000);
      $(".card4 .rank_box").removeClass("none");
      $(".card4 .rank_box").addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      await this.playBarHistoryIn("refBarRaceChart1");
      await sleep(9000);
      $(".card4 .upDown").removeClass("none");
      $(".card4 .upDown").addClass("animate__animated animate__fadeInUp");
      // await sleep(500);
      for (var i = 0; i < 17; i++) {
        this.playNumVaryIn("#per" + i);
      }
    },
    loadChart1() {
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      var option = {
        color: [
          "#D61519",
          "#667FDF",
          "#647DDA",
          "#627AD3",
          "#5F78CF",
          "#5E75C9",
          "#5B73C2",
          "#5970BF",
          "#566FB9",
          "#5369B3",
          "#4F66AF",
          "#4F64AA",
          "#4A62A3",
          "#4A5F9F",
          "#485E99",
          "#455994",
          "#40598F",
          "#3F5389",
          "#3F5183",
          "#3F5183",
        ],
        series: [
          {
            name: "姓名",
            type: "pie",
            radius: "58%",
            center: ["50%", "50%"],
            data: [
              { value: 27198, name: "广州" },
              { value: 20725, name: "上海" },
              { value: 20707, name: "深圳" },
              { value: 16857, name: "河北" },
              { value: 14423, name: "粤东" },
              { value: 13509, name: "东莞" },
              { value: 13328, name: "佛山" },
              { value: 13211, name: "浙江" },
              { value: 12751, name: "湖北" },
              { value: 12556, name: "粤西" },
              { value: 11861, name: "北京" },
              { value: 9186, name: "珠海" },
              { value: 8928, name: "西安" },
              { value: 8584, name: "福建" },
              { value: 6645, name: "成都" },
              { value: 5941, name: "江苏" },
              { value: 4904, name: "辽宁" },
              { value: 4182, name: "山东" },
              { value: 3273, name: "海南" },
              { value: 1891, name: "长春" },
            ],
            label: {
              formatter: "{b|{b}：}{c} ",
              // backgroundColor: "#F6F8FC",
              fontSize: 16,
              fontWeight: "bold",
              rich: {
                b: {
                  color: "#4C5058",
                  fontSize: 16,
                  fontWeight: "bold",
                },
              },
            },
            // animationType: "scale",
            animationEasing: "circularOut",
            // animationDelay: 200,
            animationDurationUpdate: function (idx) {
              return idx * 100;
            },
            animationDelayUpdate: function (idx) {
              return idx * 100;
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>